<template>
  <div class="filter-box">
    <!-- 表单 -->
    <div class="filter-form">
      <el-form :inline="true" labelWidth="85px">
        <el-form-item label="操作人：" label-width="90px">
          <ml-input
            placeholder="请输入操作人"
            v-model="formParams.userName"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="手机号：" label-width="90px">
          <ml-input
            placeholder="请输入手机号"
            v-model="formParams.userPhone"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="操作IP：" label-width="90px">
          <ml-input
            placeholder="请输入操作IP"
            v-model="formParams.requestIp"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="操作设备型号：" label-width="110px">
          <ml-input
            placeholder="请输入操作设备型号"
            v-model="formParams.deviceModel"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="操作版本号：" label-width="110px">
          <ml-input
            placeholder="请输入操作版本号"
            v-model="formParams.deviceFirmwareVersionCode"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="操作时间：" label-width="90px">
          <ml-date-time
            startPlaceholder="操作开始时间"
            endPlaceholder="操作结束时间"
            v-model:startTimeValue="formParams.operationStartTime"
            v-model:endTimeValue="formParams.operationEndTime"
          ></ml-date-time>
        </el-form-item>

        <el-form-item>
          <ml-button type="" @handleClick="submit">搜索</ml-button>
          <ml-button type="primary" @handleClick="reset"> 重置 </ml-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

const emits = defineEmits(["submit", "reset"]);

// 默认数据
const defaultParams = () => ({
  userName: "",
  userPhone: "",
  operationStartTime: "",
  operationEndTime: "",
  requestIp: "",
  deviceModel: "",
  deviceFirmwareVersionCode: "",
});
const formParams = reactive(defaultParams());

const submit = () => {
  emits("submit", formParams);
};

const reset = () => {
  const defaultData = defaultParams();
  for (let i in defaultData) {
    formParams[i] = defaultData[i];
  }
  emits("reset", defaultData);
};

/* 设置筛选参数并查询 */
const setFilterParams = (params) => {
  Object.assign(formParams, params);
  setTimeout(() => {
    submit();
  });
};

// 获取筛选参数
const getFilterParams = () => formParams;

defineExpose({
  setFilterParams,
  getFilterParams,
  submit,
});
</script>

<style lang="scss" scoped>
.filter-form {
  user-select: none;
}
</style>
