<template>
  <div class="main-manage-container log-container">
    <panel-manage panelTitle="设备型号日志管理" @refresh="init">
      <div class="table-action-container">
        <filter-box
          @reset="resetCall"
          @submit="filterCall"
          ref="filterBoxRef"
        ></filter-box>

        <table-box ref="tableBoxRef"></table-box>
      </div>
    </panel-manage>
  </div>
</template>
success
<script setup>
import filterBox from "./components/filter.vue";
import tableBox from "./components/table.vue";

import {
  onMounted,
  ref,
  getCurrentInstance,
  computed,
  reactive,
  nextTick,
} from "vue";

// 筛选表格组件
// ref
const filterBoxRef = ref();

/* 表格组件操作 */
const tableBoxRef = ref();
const filterCall = (params) => {
  tableBoxRef.value.fetchFilterData(params);
  openTableLoading();
};
const resetCall = (params) => {
  tableBoxRef.value.resetParams(params);
  openTableLoading();
};

// 表格加载
const openTableLoading = () => {
  tableBoxRef.value.openTableLoading();
};

// 初始
const init = () => {
  filterBoxRef.value.submit();
};

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/main_page.scss";
</style>
<style lang="scss">
.log-container {
  .panel-manage-container__content {
    grid-template-rows: 1fr;
  }
}
</style>
