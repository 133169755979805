<template>
  <panel-main ref="panelMainRef">
    <!-- 表格区域 -->
    <div class="table-content">
      <!-- 表格 -->
      <ml-table
        ref="tableRef"
        :tableData="tableData"
        :templateData="templateData"
        :pageParams="pageParams"
        tableHeight="100%"
        :useJumpClick="false"
      >
        <template #deviceFirmwareVersionCode="{ row }">
          {{
            row.deviceFirmwareVersionCode
              ? "v" + row.deviceFirmwareVersionCode
              : "-"
          }}
        </template>
      </ml-table>
    </div>
  </panel-main>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed,
  nextTick,
  watch,
} from "vue";
import { useTableParamsModule, usePageModule } from "@/utils/mixin";

// 面板
// ref
const panelMainRef = ref();

// 表格配置
const {
  resetParams,
  tableData,
  pageParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  tableRef,
  filterParams,
} = useTableParamsModule({
  fetchTableName: "fetchGetDeviceOperationLogPageList",
});

// 表格模板数据
const templateData = [
  {
    type: "index",
    label: "序号",
    width: "120",
    align: "left",
  },
  {
    prop: "userName",
    label: "操作人",
    minWidth: "150",
    align: "left",
  },
  {
    prop: "userPhone",
    label: "手机号",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "requestIp",
    label: "操作IP",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "deviceModel",
    label: "操作设备型号",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "deviceFirmwareVersionCode",
    label: "操作版本号",
    minWidth: "170",
    showTemplate: true,
    align: "left",
  },
  {
    prop: "operationContent",
    label: "操作内容",
    minWidth: "200",
    align: "left",
  },
  {
    prop: "operationTime",
    label: "操作时间",
    minWidth: "170",
    align: "left",
  },
];

// *********************************************************************************************************
// 表格数据操作  START
// *********************************************************************************************************

// *********************************************************************************************************
// 表格数据操作  END
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 START
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 END
// *********************************************************************************************************

defineExpose({
  resetParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
});
</script>

<style lang="scss" scoped>
.table-content {
  height: 100%;

  // 项目成员

  .head-sculpture-group {
    display: flex;
    .head-box {
      margin-right: 8px;
    }
  }
}

.byte-group {
  width: 100%;
  display: flex;
  margin-top: 15px;
  position: relative;
  margin-bottom: 10px;
  .item {
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.show-line-tips {
  position: absolute;
  right: 4px;
  width: auto;
  bottom: -25px;
}
</style>

<style lang="scss">
.file-type-form {
  .storage-capacity-item {
    margin-bottom: 27px;
  }
}
</style>
